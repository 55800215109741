<template>
    <section id="body" class="fill-height">
        <v-card light class="elevation-24 mx-auto py-5">
            <slot />
        </v-card>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.v-card {
  margin-top: -64px;
  max-width: 95%;
  border-radius: 6px;
  /* min-height: 80vh; */
}
</style>
